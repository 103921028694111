// 3rd party libraries
import { createPlateEditor, serializeHtml } from '@udecode/plate'
import HtmlReactParser from 'html-react-parser'

// Components
import { pluginshtml } from './Plate'

import VideoPlayer from '../videoPlayer/VideoPlayer'

type Props = {
    id?: string
    nodes: Array<any>
}

export default function ReadOnlyObituaryPlate({ nodes }: Props) {
    const editor = createPlateEditor({
        plugins: pluginshtml,
        // components: componentshtml,
    })
    // console.log('edit', editor)

    const html = serializeHtml(editor, {
        nodes: nodes,
        stripWhitespace: false,
    })

    let parsedHtml = HtmlReactParser(html)

    if (Array.isArray(parsedHtml)) {
        parsedHtml = parsedHtml.map((el, i) => {
            if (el.props.as === 'video') {
                return <VideoPlayer key={i} src={el.props.children.props.src} />
            }
            return el
        })
        return parsedHtml
    } else if (typeof parsedHtml === 'string') {
        return []
    } else {
        return parsedHtml
    }
}
