// React&NextJS
import React, { MouseEventHandler, useEffect, useState } from 'react'
import NextLink from 'next/link' // alias of Link

// 3rd party libraries
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
// Styles
import s from './Card.module.scss'
import classnames from 'classnames'
// Components
import { Button } from '../../core/button/Button'
import ColorBlob from '../../../hooks/useBlob'
import { ButtonMap } from '../../components/buttonMap/ButtonMap'
import { Container } from '../../core/container/Container'
import { Image } from '../../core/image/Image'
import ImageBlob from '../../components/imageBlob/ImageBlob'
import { Text } from '../../core/text/Text'
import ReadOnlyObituaryPlate from '../../components/plate/ReadObituaryPlate'
import { PlateObj } from '../../../types/types'
import helperFunctions from '../../../utils/helperFunctions'

const c = classnames.bind(s)

type Props = {
    header: string
    headerSize?: 'h1' | 'h2' | 'h3' | 'h4'
    size?: '1' | '3' | '4'
    imageSrc: string
    imagePosition?: 'l' | 'r'
    lifeTime?: string
    description?: string | Array<PlateObj>
    scrapedInfo?: string
    className?: string
    loading?: 'lazy' | 'eager'
    type?: string
    to?:
        | Array<{
              id?: string
              link: string
              label: string
              onClick?: MouseEventHandler<HTMLButtonElement>
          }>
        | {
              id?: string
              link: string
              label: string
              onClick?: MouseEventHandler<HTMLButtonElement>
          }
}

export default function Card({
    size,
    header,
    headerSize,
    imageSrc,
    imagePosition,
    lifeTime,
    description,
    scrapedInfo,
    className,
    type,
    loading = 'eager',
    to,
    ...props
}: Props) {
    const [ref, inView] = useInView()
    const controls = useAnimation()
    const [bioText, setBioText] = useState<JSX.Element | JSX.Element[]>(null)

    const createBioText = () => {
        if (typeof description === 'object') {
            const bioNodes = description
            const bioHtml = ReadOnlyObituaryPlate({
                nodes: bioNodes,
            })

            return bioHtml
        }
        return []
    }

    useEffect(() => {
        // create the biotext
        setBioText(createBioText())
    }, [])

    const animationVariants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }

    const [loaded, setLoaded] = useState(false)
    const animationControls = useAnimation()
    useEffect(() => {
        if (loaded) {
            animationControls.start('visible')
        }
    }, [loaded])

    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [inView, controls])

    let buttons

    if (Array.isArray(to)) {
        buttons = (
            <ButtonMap>
                {to.map((obj, i) => {
                    if (obj.onClick) {
                        return (
                            <Button
                                id={obj.id || ''}
                                variant="text"
                                key={i}
                                label={obj.label}
                                onClick={obj.onClick}
                            />
                        )
                    } else {
                        return (
                            <Button
                                variant="text"
                                key={i}
                                label={obj.label}
                                to={obj.link}
                            />
                        )
                    }
                })}
            </ButtonMap>
        )
    } else if (to) {
        buttons = (
            <Button
                id={to.id || ''}
                variant="text"
                label={to.label}
                to={to.link}
                onClick={to.onClick || null}
            />
        )
    }

    const clickCreateBtn = () => {
        if (type === 'funeral') {
            console.log(header)
            const btn = document.getElementById(`${header}-${type}`)
            btn.click()
        }
    }

    if (size === '1') {
        return (
            // @ts-ignore
            <AnimatePresence>
                <motion.section
                    // @ts-ignore
                    key={to && to?.id}
                    className={c(className, s.section)}
                    ref={ref}
                    initial="hidden"
                    animate={controls}
                    transition={{ duration: 1, ease: 'easeOut' }}
                    variants={{
                        visible: {
                            opacity: 1,
                        },
                        hidden: {
                            opacity: 0,
                        },
                    }}
                >
                    <NextLink
                        href={
                            to && Array.isArray(to)
                                ? to[0]?.link
                                : !Array.isArray(to)
                                ? to?.link
                                : ''
                        }
                    >
                        <Container className={c(s.card, s[`size${size}`])}>
                            <ImageBlob
                                className={c(s[imagePosition])}
                                url={imageSrc}
                            />
                            <div
                                className={c(
                                    s.details,
                                    s['d_' + imagePosition]
                                )}
                            >
                                <Text
                                    variant={headerSize}
                                    tag="h3"
                                    label={header}
                                />
                                <Text variant="date" label={lifeTime} />
                                <Text variant="p-18" className={s.shortText}>
                                    {bioText &&
                                        helperFunctions.shortendBio(bioText)}
                                    {typeof description === 'string' &&
                                        description}
                                </Text>

                                {to && buttons}
                            </div>
                        </Container>
                    </NextLink>
                </motion.section>
            </AnimatePresence>
        )
    }
    return (
        // @ts-ignore
        <AnimatePresence>
            <motion.div
                // initial={{ opacity: 0 }}
                // animate={{ opacity: 1 }}
                // exit={{ opacity: 0 }}
                // @ts-ignore
                key={to && to?.id}
            >
                <div
                    className={c(s.card, s[`size${size}`], s.dynamicCard)}
                    {...props}
                >
                    {(size === '4' || size === '3') && type !== 'funeral' && (
                        <>
                            <NextLink
                                href={
                                    to && Array.isArray(to)
                                        ? to[0]?.link
                                        : !Array.isArray(to) && to?.link
                                        ? to?.link
                                        : ''
                                }
                            >
                                <div>
                                    <motion.div
                                        className={s.aspectRatio}
                                        initial={'hidden'}
                                        animate={animationControls}
                                        variants={animationVariants}
                                        transition={{
                                            ease: 'easeOut',
                                            duration: 1,
                                        }}
                                    >
                                        <Image
                                            className={s.card__image}
                                            centerFace={
                                                size === '3' ? false : true
                                            }
                                            loading={loading}
                                            src={imageSrc}
                                            onLoad={() => setLoaded(true)}
                                            height={305}
                                            alt=""
                                        />
                                        <ColorBlob stayBelow />
                                    </motion.div>

                                    <div className={s.text}>
                                        <Text variant={headerSize} tag="h3">
                                            {header}
                                        </Text>
                                        {size === '4' && (
                                            <>
                                                <Text
                                                    variant="date"
                                                    className="infoText"
                                                >
                                                    {lifeTime}
                                                </Text>
                                                <Text
                                                    variant="p"
                                                    className={s.shortText}
                                                >
                                                    {description}
                                                </Text>
                                                {scrapedInfo && (
                                                    <Text
                                                        variant="p-small"
                                                        className={s.scrapeText}
                                                    >
                                                        {scrapedInfo}
                                                    </Text>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </NextLink>
                            {buttons}
                        </>
                    )}
                    {(size === '4' || size === '3') && type === 'funeral' && (
                        <>
                            <div onClick={clickCreateBtn}>
                                <motion.div
                                    className={s.aspectRatio}
                                    initial={'hidden'}
                                    animate={animationControls}
                                    variants={animationVariants}
                                    transition={{
                                        ease: 'easeOut',
                                        duration: 1,
                                    }}
                                >
                                    <Image
                                        className={s.card__image}
                                        centerFace={size === '3' ? false : true}
                                        loading={loading}
                                        src={imageSrc}
                                        onLoad={() => setLoaded(true)}
                                        height={305}
                                        alt=""
                                    />
                                    <ColorBlob stayBelow />
                                </motion.div>

                                <div className={s.text}>
                                    <Text variant={headerSize} tag="h3">
                                        {header}
                                    </Text>
                                    {size === '4' && (
                                        <>
                                            <Text
                                                variant="date"
                                                className="infoText"
                                            >
                                                {lifeTime}
                                            </Text>
                                            <Text
                                                variant="p"
                                                className={s.shortText}
                                            >
                                                {description}
                                            </Text>
                                            {scrapedInfo && (
                                                <Text
                                                    variant="p-small"
                                                    className={s.scrapeText}
                                                >
                                                    {scrapedInfo}
                                                </Text>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            {buttons}
                        </>
                    )}
                </div>
            </motion.div>
        </AnimatePresence>
    )
}

Card.defaultProps = {
    size: '4',
    header: 'Friðrik Snær',
    headerSize: 'h2',
    imagePosition: 'l',
}
