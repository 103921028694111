// @ts-nocheck
// TODO: typesafe
// React&NextJS
import React, { InputHTMLAttributes } from 'react'
// Types
import { ButtonType as Button } from '../../../types/types'
// Styles
import s from './ButtonMap.module.scss'
import classnames from 'classnames'

const c = classnames.bind(s)

type Props = {
    className?: string
    children: Array<InputHTMLAttributes<Button>>
    [key: string]: unknown
}

export const ButtonMap = ({ className, children, ...props }: Props) => {
    return (
        <div className={c(s.container, className)} {...props}>
            {children.map((button, i) => {
                if (button) {
                    if (children.length != i + 1) {
                        return (
                            <React.Fragment key={i}>
                                {button}
                                <div className={s.line} />
                            </React.Fragment>
                        )
                    } else {
                        return button
                    }
                }
            })}
        </div>
    )
}
